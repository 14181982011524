<template>
  <div class="Description document-content">
    <Des class="markdown-body"></Des>
  </div>
</template>

<script setup>
import Des from "./mdDocument/Baas-api.md";
// // 代码高亮

// import "highlight.js/styles/github.css";

// // 其他元素使用 github 的样式

// import "github-markdown-css";
export default {
  data() {
    return {};
  },
  components: {
    Des,
  },
};
</script>

<style lang="less" scoped>
</style>